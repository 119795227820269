<template>
  <a-card title="课件版本管理">
    <template #extra><a @click="handleBack">返回</a></template>
    <div class="tableToolBar">
      <a-button type="primary" @click="handleCreate">编辑开发版</a-button>
    </div>
    <a-table
      :dataSource="tableState.data"
      :columns="tableState.columns"
      rowKey="id"
      :pagination="tableState.pagination"
      :loading="tableState.loading"
      @change="handleTableChange"
      :scroll="{ x: 1800 }"
    >
      <template #action="{ record }">
        <div v-permission="PermissionMap.COURSEWARE_VERSION">
          <a @click="handleJumpInfo(record)">
            详情
          </a>
          <a-divider type="vertical"/>
          <a-button type="link" @click="setVersionDev(record)" class="noPadding" :disabled="record.version_type === 0">
            复制到开发版
          </a-button>
          <a-divider type="vertical"/>
          <a-button type="link" @click="setVersionTest(record)" :disabled="![0].includes(record.version_type)" class="noPadding">
            设为测试版
          </a-button>
          <a-divider type="vertical"/>
          <a-button v-permission="PermissionMap.COURSEWARE_PUBLISH" type="link" @click="setVersionProd(record)" :disabled="![1, 3].includes(record.version_type)" class="noPadding">
            设为正式版
          </a-button>
          <a-divider v-permission="PermissionMap.COURSEWARE_PUBLISH" type="vertical"/>
          <a-button type="link" @click="handleDelete(record)" :disabled="[1,2].includes(record.version_type)">
            删除
          </a-button>
        </div>
      </template>
      <template #type="{ text }">
        <span>{{ coursewareTypeMap[text].name }}</span>
      </template>
      <template #tags="{ text }">
        <span v-if="!text?.length">--</span>
        <span v-else>
              <a-tooltip
                :title="text.map((tItem) => tItem.tag).toString()"
                placement="topLeft"
              >
                <span :key="item.tag" v-for="item in text">
                  <a-tag class="tag_item_label">{{ item.tag }}</a-tag>
                </span>
              </a-tooltip>
            </span>
      </template>
      <template #version_type="{ text }">
        <resource-version-tag :status="text"/>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { PermissionMap } from '@/utils/auth'
import { computed, onBeforeMount, reactive, ref } from 'vue'
import { debounce } from '@/utils/common'
import {
  getCoursewareVersionList,
  setCoursewareVersionTest,
  setCoursewareVersionDevelop,
  setCoursewareVersionProduction,
  deleteCoursewareVersion,
  getCoursewareDevelopVersion, getCoursewareInfo
} from '@/services/courseware'
import ResourceVersionTag from '@/components/common/ResourceVersionTag'
import { coursewareTypeMap } from '@/utils/dataMap'

export default {
  name: 'VersionList',
  components: {
    ResourceVersionTag
  },
  setup () {
    const currentCourseware = ref({})
    const router = useRouter()
    const route = useRoute()
    const pageData = reactive({
      disabled: false,
      type: 1,
      firstLoading: true,
      advanced: false,
      tagParams: {
        pageSize: 50,
        page: 1,
        keyword: ''
      },
      modalVisible: false
    })
    const tableState = reactive({
      data: [],
      columns: [],
      loading: true,
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
        showTotal: total => `共${total}条`,
        showQuickJumper: true
      }
    })
    tableState.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        width: 120
      },
      {
        title: '课件名称',
        dataIndex: 'name',
        ellipsis: true
      },
      {
        title: '课件类型',
        dataIndex: 'type',
        ellipsis: true,
        slots: { customRender: 'type' }
      },
      // {
      //   title: '教材',
      //   dataIndex: 'book.name',
      //   ellipsis: true
      // },
      // {
      //   title: '章节',
      //   dataIndex: 'section_breadcrumb',
      //   ellipsis: true
      // },
      // {
      //   title: '制作者',
      //   dataIndex: 'author_name',
      //   ellipsis: true
      // },
      // {
      //   title: '标签',
      //   dataIndex: 'tags',
      //   ellipsis: true,
      //   slots: { customRender: 'tags' }
      // },
      {
        title: '版本类型',
        dataIndex: 'version_type',
        width: 200,
        slots: { customRender: 'version_type' }
      },
      {
        title: '创建时间',
        dataIndex: 'created_at',
        width: 180,
        ellipsis: true
      },
      {
        title: '更新时间',
        dataIndex: 'updated_at',
        width: 180,
        ellipsis: true
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 440,
        slots: { customRender: 'action' }
      }
    ]

    const getList = debounce(async (params) => {
      tableState.loading = true
      params = { page: 1, ...params, courseware_id: route.params.id }
      const { items, pagination } = await getCoursewareVersionList(params)
      tableState.data = items
      tableState.loading = false
      tableState.pagination = { ...tableState.pagination, ...pagination }
    })
    const handleBack = () => {
      history.go(-1)
    }
    const handleJumpInfo = (record) => {
      if (currentCourseware.value.type === 'ppt') {
        router.push(`/resource/courseware/ppt/version-detail/${record.id}`)
      } else if (currentCourseware.value.type === 'plan') {
        router.push(`/resource/courseware/lessonPlan/version-detail/${record.id}/0/plan`)
      } else if (currentCourseware.value.type === 'speak') {
        router.push(`/resource/courseware/speak/version-detail/${record.id}/speak`)
      } else {
        router.push(`/resource/courseware/file/version-detail/${record.id}`)
      }
    }
    const handleTableChange = (pag) => {
      tableState.pagination = pag
      getList({ page: tableState.pagination.current })
    }
    const getCoursewareData = async () => {
      currentCourseware.value = await getCoursewareInfo(route.params.id)
    }
    const handleCreate = async () => {
      try {
        if (currentCourseware.value.type === 'ppt') {
          await getCoursewareDevelopVersion(route.params.id)
          router.push(`/resource/courseware/ppt/edit/${route.params.id}`)
        } else if (currentCourseware.value.type === 'plan') {
          await getCoursewareDevelopVersion(route.params.id)
          router.push(`/resource/courseware/lessonPlan/edit/${route.params.id}/0/plan`)
        } else if (currentCourseware.value.type === 'speak') {
          await getCoursewareDevelopVersion(route.params.id)
          router.push(`/resource/courseware/speak/edit/${route.params.id}/speak`)
        } else {
          router.push(`/resource/courseware/file/edit/${route.params.id}`)
        }
      } catch (e) {
        console.error(e)
        message.error('请求错误或超时')
      }
    }
    const handleDelete = async (record) => {
      await deleteCoursewareVersion(record.id)
      message.success('操作成功')
      getList()
    }

    onBeforeMount(async () => {
      await Promise.all([
        getList(),
        getCoursewareData()
      ])
      pageData.firstLoading = false
    })

    const setVersionTest = async (record) => {
      await setCoursewareVersionTest(record.id)
      message.success('设置成功')
      getList()
    }
    const setVersionDev = async (record) => {
      await setCoursewareVersionDevelop(record.id)
      message.success('设置成功')
      getList()
    }
    const setVersionProd = async (record) => {
      await setCoursewareVersionProduction(record.id)
      message.success('设置成功')
      getList()
    }

    return {
      coursewareTypeMap,
      tableState,
      pageData,
      PermissionMap,
      handleJumpInfo,
      handleBack,
      handleTableChange,
      handleCreate,
      handleDelete,
      setVersionTest,
      setVersionProd,
      setVersionDev
    }
  }
}
</script>

<style lang="less" scoped>
.noPadding{
  padding: 0;
}
.tableToolBar{
  margin-bottom: 12px;
}
</style>

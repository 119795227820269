<template>
  <a-tag :color="statusItem.color">{{ statusItem.name }}</a-tag>
</template>

<script>
import { computed } from 'vue'
export default {
  name: 'ResourceVersionTag',
  props: {
    status: {
      type: Number
    }
  },
  setup (props) {
    const statusMap = {
      0: { name: '开发版', color: 'cyan' },
      1: { name: '测试版', color: 'blue' },
      2: { name: '正式版', color: 'gold' },
      3: { name: '历史版', color: 'orange' },
      4: { name: '暂存版', color: 'lightgray' }
    }
    const statusItem = computed(() => statusMap[props.status])
    return {
      statusItem
    }
  }
}
</script>

<style scoped>

</style>
